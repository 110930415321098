var site = site || {};

(function ($) {
  var $formatters = $();
  var $closeButtons = $();
  var animationSpeed = 400;
  var mouseOutDelay = 800;
  var drawerOpen = false;
  var waited = false;
  var waiting = false;
  var isOver = false;
  var keepOpen = false;
  var initialized = false;
  // Drawers functionality
  var init = function (context) {
    var $footer = $('.footer', context);
    var $stickyFooter = $('.footer--sticky', context);
    var offset = 0;
    var stickyFooterHeight = $stickyFooter.height();

    $formatters = $('.js-trigger-sticky-tout', context);
    var $container = $('.js-sticky-footer-block', context);

    $closeButtons = $('.js-sticky-footer-close', $container);
    var $stickyFooterToutFormatter = $('.js-sticky-footer-tout-formatter', context);
    var $stickyBlockLink = $('.js-sticky-footer-pc-links', context);
    var $liveChatConent = $('.js-aveda-live-chat', context);
    var $mobileChatLink = $('.js-sticky-footer-mobile-link', $liveChatConent);

    // Only run once
    if (initialized) {
      return;
    }
    initialized = true;

    if (!$footer.length) {
      return;
    }

    function _setOffset() {
      var stickyClass = 'is-sticky';
      var $windowSel = $(window);

      offset = $footer.offset().top - $windowSel.height() + stickyFooterHeight;
      $stickyFooter.toggleClass(stickyClass, $windowSel.scrollTop() < offset);
    }

    var footerSetOffSet = _.debounce(_setOffset, 300);

    $(window).scroll(footerSetOffSet);
    // Use a timeout to ensure this is run after other scripts set their dimensions
    setTimeout(_setOffset, 60);

    function _mouseMove(event, over) {
      isOver = over === true ? true : false;
      if (!drawerOpen || keepOpen) {
        return;
      }
      if (event) {
        isOver = !!$(event.target).closest('.drawer-container').length;
      }

      if (!waiting && !waited) {
        // Set a delay before we check the cursor again
        waiting = true;
        setTimeout(function () {
          waited = true;
          waiting = false;
          _mouseMove(false, isOver);
        }, mouseOutDelay);
      } else if (waited) {
        if (!isOver) {
          // If we're still not over the container, close it
          _close();
        }
        waited = false;
      }
    }

    function _close(event) {
      if (typeof event != 'undefined') {
        event.preventDefault();
      }
      close();
      $stickyFooterToutFormatter.attr('tabindex', '-1');
    }

    $formatters.each(function () {
      var $formatter = $(this);
      var $pane = $stickyFooterToutFormatter.filter(function () {
        return !(
          $(this).css('visibility') === 'hidden' ||
          $(this).css('display') === 'none' ||
          $(this).css('opacity') === 0
        );
      });
      var $trigger = $('.js-sticky-footer-pc-links', $formatter);
      var hidePane = $pane.is(':hidden');
      var paneHeight = $pane.show().height();

      $pane.data('paneHeight', paneHeight);

      function _mouseOver(currentElement) {
        var speed = drawerOpen ? 0 : animationSpeed;
        var $tout = currentElement.parent().find('.js-sticky-footer-tout-formatter');

        open(currentElement, $tout, speed);
        $stickyFooterToutFormatter.attr('tabindex', '0').focus();
        site.restrict_navigation($stickyFooterToutFormatter);
      }

      $('.js-sticky-footer-block-tout', context)
        .once()
        .on('click', function (e) {
          if (e.target !== this) {
            return;
          }
          var $panel = $(this).find(
            '.js-trigger-sticky-tout.sticky-footer-block-formatter__tout-node'
          );

          if ($(window).width() > 1023) {
            $('.js-sticky-footer-pc-links', $panel).triggerHandler('click');
          }

          return;
        });

      $stickyBlockLink.once().on('click', function () {
        event.stopPropagation();
        event.preventDefault();
        var $this = $(this);
        var url = $this.attr('href');

        if ($this.parent('.js-trigger-sticky-tout').length) {
          _mouseOver($this);

          return;
        }
        if ($this.attr('target') == '_blank') {
          window.open(url, '_blank');
        } else {
          window.location = url;
        }
      });
      $trigger.on('focusout', function () {
        $('.js-sticky-footer-pc-links.custom-outline').removeClass('custom-outline');
      });
      if (hidePane) {
        $pane.hide();
      } else {
        drawerOpen = true;
      }
    });

    $('body', context).on('mousemove', _mouseMove);
    $closeButtons.on('click', _close);
    $closeButtons.on('keydown', function (e) {
      if (site.getKeycode(e) === 13) {
        e.preventDefault();
        $('.js-sticky-footer-pc-links.active', context).focus().addClass('custom-outline');
        _close();
      }
    });
  };
  // Drawers Open
  var open = function ($trigger, $pane, speed, keepOpen) {
    var $windowSelector = $(window);
    var $stickyFooter = $trigger.closest('.js-sticky-footer-block-formatter-v1');

    if (drawerOpen) {
      $pane.siblings('.js-sticky-footer-tout-formatter').hide().css('bottom', -2);
    }
    drawerOpen = true;
    $pane
      .stop()
      .show()
      .data('paneHeight', $trigger.height() + 25);
    if ($pane.closest('.is-sticky').length === 0) {
      $pane
        .stop()
        .show()
        .data(
          'paneHeight',
          $windowSelector.height() - ($stickyFooter.offset().top - $windowSelector.scrollTop())
        );
    }
    if ($trigger.hasClass('sticky-footer-block-tout')) {
      $pane.data('paneHeight', $pane.data('paneHeight') + 20);
    }
    $pane
      .stop()
      .show()
      .animate(
        {
          bottom: $pane.data('paneHeight')
        },
        speed
      );
    $('.js-sticky-footer-pc-links', $stickyFooter).removeClass('active');
    $trigger.addClass('active');
    this.keepOpen = !!keepOpen;
  };
  // Drawers Close
  var close = function ($pane) {
    if (typeof $pane == 'undefined') {
      $pane = $('.js-sticky-footer-tout-formatter:visible');
    }
    if (!$pane.is(':visible')) {
      return;
    }
    drawerOpen = false;
    keepOpen = false;
    $pane.stop().animate(
      {
        bottom: -2
      },
      animationSpeed,
      function () {
        $pane.hide();
      }
    );
    $('.js-sticky-footer-pc-links').removeClass('active');
  };

  Drupal.behaviors.stickyFooterV1 = {
    attach: function (context) {
      var $disableLargeElements = $('.js-sticky-footer-block-formatter-v1 .disable-pc', context);
      var $disableSmallElements = $(
        '.js-sticky-footer-block-formatter-v1 .disable-mobile',
        context
      );
      var mobileLinks = $(
        '.js-sticky-footer-block-formatter-v1 .disable-pc:visible',
        context
      ).length;
      var $rightLargeElements = $('.js-sticky-footer-block-right.disable-mobile', context);
      var $chatBlock = $('.js-sticky-footer-chat-block', context);
      var $livePersonChatBlock = $('.js-lp-account-id', context);
      var userAgent = navigator.userAgent || navigator.vendor || window.opera;

      $disableLargeElements
        .addClass('col-md-' + mobileLinks)
        .first()
        .addClass('no-bar');
      $disableSmallElements.first().addClass('no-bar');
      if ($rightLargeElements.length > 0) {
        $rightLargeElements.first().addClass('no-bar');
      } else {
        $disableSmallElements.last().addClass('no-bar');
      }
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        $('.js-sticky-footer-ios-businesschat').hide();
      }
      $chatBlock.once().on('click', function (e) {
        e.preventDefault();
        $('[data-lp-event]', context).get(0).click();
      });
      init(context);
      close();
    }
  };
})(jQuery);
